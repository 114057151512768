import React from "react"
import "./styles.scss"
import Slider from "react-slick"

function Wrapper({ children }) {
    return <div className={"fluid-container missions-container"}>{children}</div>
}

function MissionItem({ icon, text, head }) {
    return (
        <div className="missions-list__item">
            <div className="mission-icon-wrapper">
                <img src={icon} alt={"item.text"} />
            </div>
            <div className="mission-head-wrapper">
                <h1 className={"text-center"}>{head}</h1>
            </div>
            <div className="mission-text-wrapper">
                <p className={"text-center"}>{text}</p>
            </div>
        </div>
    )
}

const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"dot"} />,
}

export default function Missions({ title, description, items }) {
    return (
        <Wrapper>
            <div className="mission-header">
                <p className={"text-center mission-title"}>{title}</p>
                <p className={"text-center mission-description"}>{description}</p>
            </div>

            <div className="missions-list mission-container-mobile">
                {items.map((item, i) => (
                    <MissionItem {...item} key={i} />
                ))}
            </div>

            <div className={"mission-container-slider-desktop"}>
                <Slider {...settings}>
                    {items.map((item, i) => (
                        <div key={i}>
                            <MissionItem {...item} />
                        </div>
                    ))}
                </Slider>
            </div>
        </Wrapper>
    )
}
