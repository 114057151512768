import React, { useEffect, createRef } from "react"
import "./styles.scss"

import waveSrc from "../../assets/crop3.png"

import HighLightTitle from "../../ui/HighlightTitle"
import promoAnimation from "../../assets/lottie-animations/home-page-promo.json"
import lottie from "lottie-web"

function Content({ title, highlighted, color, description }) {
    const animationContainer = createRef()

    useEffect(() => {
        let animation = {}

        animation = lottie.loadAnimation({
            container: animationContainer.current,
            animationData: promoAnimation,
            renderer: "svg",
            loop: true,
            autoplay: false,
        })

        animation.play()

        return () => animation.hasOwnProperty("destroy") && animation.destroy()
    }, [])

    return (
        <div className={"promo-container"}>
            <div className={"promo-container__quote-wrapper"}>
                <div className={"mb-1p25"}>
                    <HighLightTitle
                        className={"light-text promo-text-size"}
                        title={title}
                        highlight={highlighted}
                        color={color}
                    />
                </div>
                <div>
                    <p className={"light-text"}>{description}</p>
                </div>
            </div>

            <div className={"promo-image-wrapper"} ref={animationContainer} />
        </div>
    )
}

export default function PromoSection(props) {
    return (
        <div className={"promo-section-container"}>
            <div className="content-wrapper">
                <Content {...props} />
            </div>

            <div className={"background-wave"}>
                <img src={waveSrc} alt="" />
            </div>

            <div className="promo-section-container__footer-svg-wrapper">
                <div className={"promo-section-container__footer-svg-wrapper--mobile"} />
            </div>
        </div>
    )
}
