import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../components/seo"

import PromoSection from "../components/PromoSection"
import Missions from "../components/Missions"
import Portfolio from "../components/PortfolioSection"
import { home } from "../DB"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const IndexPage = () => {
    const homePageImages = useStaticQuery(graphql`
        query HomePagePreviewImages {
            calendar_ImageBackDevice: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calendar/preview" }
            ) {
                publicURL
                name
            }
            calendar_ImagePopUpImage: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calendar/content" }
                name: { eq: "app-popup-card" }
            ) {
                publicURL
                name
            }
            calendar_ImageFrontDevice: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calendar/content" }
                name: { eq: "app-popup-device" }
            ) {
                publicURL
                name
            }

            calls_ImageFrontDevice: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calls/home_page" }
                name: { eq: "front-new" }
            ) {
                publicURL
                name
            }

            calls_ImageBackDevice: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calls/home_page" }
                name: { eq: "front" }
            ) {
                publicURL
                name
            }

            calls_cutImage: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "calls/home_page" }
                name: { eq: "calls-cut" }
            ) {
                publicURL
                name
            }

            mail_ImageDevice: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "mail/preview" }
                name: { eq: "mail-prev" }
            ) {
                publicURL
                name
            }

            mail_ImageFrontCard: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "mail/content" }
                name: { eq: "card_member" }
            ) {
                publicURL
                name
            }

            mail_ImageMessage: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "mail/content" }
                name: { eq: "card-member-messages" }
            ) {
                publicURL
                name
            }
            mail_ImageCalls: file(
                sourceInstanceName: { eq: "products_images" }
                relativeDirectory: { eq: "mail/content" }
                name: { eq: "card-member-calls" }
            ) {
                publicURL
                name
            }
        }
    `)

    home.productivity.portfolio[0]._images = {
        backDevice: {
            ...homePageImages.calendar_ImageBackDevice,
            image_name: "app-popup-device__small",
            CSS_SCALE_MODIFIER: "app-popup-device__small",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "app-device-small",
        },
        frontDevice: {
            ...homePageImages.calendar_ImageFrontDevice,
            image_name: "app-popup-device-calendar",
            CSS_SCALE_MODIFIER: "app-popup-device-calendar",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "app-device",
        },
        popup: {
            ...homePageImages.calendar_ImagePopUpImage,
            image_name: "app-popup-card",
            CSS_SCALE_MODIFIER: "app-popup-card",
            INITIAL_CSS_CLASSNAME: "transparent-block-50",
            CSS_CLASSNAME: "app-pop-up-card",
        },
    }
    home.productivity.portfolio[0].INITIAL_CSS_CONTAINER_CLASSNAME = "animated-calendar-ai"
    home.productivity.portfolio[0].IMAGES_CONTAINER_CLASSNAME = "calendar-ai-images-container"
    home.productivity.portfolio[0].ANIMATION_ACTIVE_CLASS = "a-calendar-ai"
    home.productivity.portfolio[0].animation_type = "spreed_stack"

    home.productivity.portfolio[1]._images = {
        backDevice: {
            ...homePageImages.calls_ImageBackDevice,
            image_name: "app-popup-device__small",
            CSS_SCALE_MODIFIER: "app-popup-device__small",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "app-device-small",
        },
        frontDevice: {
            ...homePageImages.calls_ImageFrontDevice,
            image_name: "app-popup-device",
            CSS_SCALE_MODIFIER: "app-popup-device",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "app-device",
        },
        cutDevice: {
            ...homePageImages.calls_cutImage,
            image_name: "cut-device",
            CSS_SCALE_MODIFIER: "cut-device",
            INITIAL_CSS_CLASSNAME: "transparent-block-50",
            CSS_CLASSNAME: "cut-device",
        },
    }
    home.productivity.portfolio[1].INITIAL_CSS_CONTAINER_CLASSNAME = "animated-calls-ai"
    home.productivity.portfolio[1].IMAGES_CONTAINER_CLASSNAME = "calls-ai-images-container"
    home.productivity.portfolio[1].ANIMATION_ACTIVE_CLASS = "a-calls-ai"
    home.productivity.portfolio[1].animation_type = "spreed_stack"

    home.productivity.portfolio[2]._images = {
        calls: {
            ...homePageImages.mail_ImageCalls,
            CSS_SCALE_MODIFIER: "calls",
            image_name: "calls",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "calls-card",
        },
        device: {
            ...homePageImages.mail_ImageDevice,
            CSS_SCALE_MODIFIER: "mobile-device",
            image_name: "front-device",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "mobile-device",
        },
        frontCard: {
            ...homePageImages.mail_ImageFrontCard,
            CSS_SCALE_MODIFIER: "front-card",
            image_name: "front-card",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "front-card",
        },
        imageMessage: {
            ...homePageImages.mail_ImageMessage,
            CSS_SCALE_MODIFIER: "tasks",
            image_name: "tasks",
            INITIAL_CSS_CLASSNAME: "",
            CSS_CLASSNAME: "tasks-card",
        },
    }
    home.productivity.portfolio[2].INITIAL_CSS_CONTAINER_CLASSNAME = "animated-sync-ai"
    home.productivity.portfolio[2].IMAGES_CONTAINER_CLASSNAME = "sync-ai-images-container"
    home.productivity.portfolio[2].ANIMATION_ACTIVE_CLASS = "a-sync-ai"
    home.productivity.portfolio[2].animation_type = "spreed_stack"

    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.HOME_PAGE)
    }, [])

    return (
        <Layout>
            <SEO
                title={"Business Communication Tools | Communications Apps | Sync.AI"}
                keywords={"business communication tools"}
                description={
                    "Sync.AI offers business communication tools that make scheduling & client management easy. Get rich insights not available with other apps. Download apps here."
                }
            />
            <PromoSection {...home.promo_section} />
            <Missions {...home.mission} />
            <Portfolio {...home.productivity} />
        </Layout>
    )
}

export default IndexPage
