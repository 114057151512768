import React, { createRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { PreviewImages } from "../ProductSetcionsTemplate/Content"
import { useInView } from "react-intersection-observer"
import HighLightTitle from "../../ui/HighlightTitle"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../../analytics"

function ProjectPreview({ images, index, ...props }) {
    const [ref, inView] = useInView({
        threshold: 0.3,
    })

    const [mobileRef, mobileInView] = useInView({
        threshold: 0.2,
    })

    const prevImages = React.useMemo(() => {
        const _images = []
        Object.keys(images).forEach((key) => {
            _images.push({ src: images[key].publicURL, ...images[key] })
        })

        return {
            animation_type: "",
            images: _images,
        }
    }, [images])

    switch (index) {
        case 0: {
            // Calendar AI
            return (
                <>
                    <div className={`project-preview--mobile ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={mobileRef}>
                        <div>
                            <PreviewImages
                                inView={mobileInView}
                                animation_type={"spreed_stack"}
                                images={prevImages.images.filter(
                                    (img) => img.image_name === "app-popup-device-calendar"
                                )}
                                active_css_class={props.ANIMATION_ACTIVE_CLASS}
                                container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                                images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                            />
                        </div>
                    </div>
                    <div className={`project-preview--desktop ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={ref}>
                        <PreviewImages
                            inView={inView}
                            animation_type={"spreed_stack"}
                            images={prevImages.images}
                            active_css_class={props.ANIMATION_ACTIVE_CLASS}
                            container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                            images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                        />
                    </div>
                </>
            )
        }
        // Calls AI
        case 1: {
            return (
                <>
                    <div className={`project-preview--mobile ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={mobileRef}>
                        <div>
                            <PreviewImages
                                inView={mobileInView}
                                animation_type={"spreed_stack"}
                                images={prevImages.images.filter((img) => img.image_name === "app-popup-device")}
                                active_css_class={props.ANIMATION_ACTIVE_CLASS}
                                container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                                images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                            />
                        </div>
                    </div>
                    <div className={`project-preview--desktop ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={ref}>
                        <div>
                            <PreviewImages
                                inView={inView}
                                animation_type={"spreed_stack"}
                                images={prevImages.images}
                                active_css_class={props.ANIMATION_ACTIVE_CLASS}
                                container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                                images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                            />
                        </div>
                    </div>
                </>
            )
        }
        // Sync AI
        case 2: {
            return (
                <>
                    <div className={`project-preview--mobile ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={mobileRef}>
                        <div>
                            <PreviewImages
                                inView={mobileInView}
                                animation_type={"spreed_stack"}
                                images={prevImages.images.filter((img) => img.image_name === "front-device")}
                                active_css_class={props.ANIMATION_ACTIVE_CLASS}
                                container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                                images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                            />
                        </div>
                    </div>
                    <div className={`project-preview--desktop ${props.INITIAL_CSS_CONTAINER_CLASSNAME}`} ref={ref}>
                        <div>
                            <PreviewImages
                                inView={inView}
                                animation_type={"spreed_stack"}
                                images={prevImages.images.filter((img) => img.image_name !== "front-device")}
                                active_css_class={props.ANIMATION_ACTIVE_CLASS}
                                container_css_class={props.INITIAL_CSS_CONTAINER_CLASSNAME}
                                images_container_css_class={props.IMAGES_CONTAINER_CLASSNAME}
                            />
                        </div>
                    </div>
                </>
            )
        }
        default:
            return null
    }
}

export default function PortfolioBlock({
    images,
    _images,
    style_theme,
    nameVector,
    description,
    quote,
    anchor_link,
    text_button,
    index,
    type,
    ...props
}) {
    const handleLearnMoreClick = (type) => {
        let event = ""
        switch (type) {
            case "calendar": {
                event = "Click More Details Calendar"
                break
            }
            case "calls": {
                event = "Click More Details Calls"
                break
            }
            case "mail": {
                event = "Click More Details Mail"
                break
            }
            default:
                break
        }

        emitAnalyticsEvent(event, AnalyticsEventCategories.HOME_PAGE)
    }

    return (
        <div className={`portfolio-block ${style_theme}`}>
            <div className="portfolio-header">
                <div className="portfolio-block__preview-wrapper">
                    <div className="portfolio-block__preview-screen">
                        <ProjectPreview images={_images} index={index} {...props} />
                    </div>
                </div>
            </div>

            <div className="portfolio-body">
                <div className={"portfolio-block__header-wrapper"}>
                    <div className="portfolio-block__app-name">
                        <div className={"portfolio-block__logo-wrapper"}>
                            {images.logo && <img src={images.logo} alt="" />}
                        </div>
                        <img className={"portfolio-block__app-name-svg"} src={nameVector} alt="" />
                    </div>
                </div>

                <div className="portfolio-footer-wrapper">
                    {quote && (
                        <div className="portfolio-block__quote-wrapper">
                            <p>{quote}</p>
                        </div>
                    )}

                    <div className="portfolio-block__description-wrapper">
                        <p>{description}</p>
                    </div>

                    <div className="portfolio-block__btn-wrapper" onClick={handleLearnMoreClick.bind(null, type)}>
                        <Link to={anchor_link} className={"primary-button primary-button__small"}>
                            {text_button}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
