import React from "react"
import HighLightTitle from "../../ui/HighlightTitle"
import PortfolioBlock from "./PortfolioBlock"
import { graphql, useStaticQuery } from "gatsby"
import "./styles.scss"

function Wrapper({ children }) {
    return <div className={"fluid-container"}>{children}</div>
}

export default function Portfolio({ portfolio, ...props }) {
    const queryResult = useStaticQuery(
        graphql`
            query queryPortfolioImages {
                allFile(filter: { sourceInstanceName: { eq: "portfolio" } }) {
                    edges {
                        node {
                            relativePath
                            publicURL
                        }
                    }
                }
            }
        `
    )

    return (
        <Wrapper>
            <div className={"portfolio-list-wrapper"}>
                {portfolio.map((item, i) => {
                    const images = queryResult.allFile.edges.reduce(
                        (imagesStack, el) => {
                            if (el.node.relativePath === item.logoImageName) {
                                imagesStack.logo = el.node.publicURL
                            }

                            if (el.node.relativePath === item.previewImageScreen) {
                                imagesStack.preview = el.node.publicURL
                            }

                            if (el.node.relativePath === item.previewImageDesktop) {
                                imagesStack.desktop = el.node.publicURL
                            }

                            return imagesStack
                        },
                        { logo: null, preview: null, desktop: null }
                    )

                    return (
                        <div className={`portfolio-container ${i === 0 ? "portfolio-container--top-padding" : ""}`}>
                            {i === 0 && (
                                <div className={"portfolio-header-wrapper"}>
                                    <HighLightTitle
                                        className={"font-w-thin"}
                                        title={props.title}
                                        highlight={props.highlighted}
                                        color={props.color}
                                    />
                                </div>
                            )}
                            <div className="portfolio-wrapper">
                                <PortfolioBlock {...item} images={images} key={i} index={i} {...props} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </Wrapper>
    )
}
