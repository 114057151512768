import React from "react"
import { string } from "prop-types"

function replaceString(newS, fullS, color) {
    const pattern = "[[highlight]]"
    let result = ""
    for (let i = 0; i < fullS.length; ++i) {
        if (fullS.substring(i, i + pattern.length) === pattern) {
            result = (
                <h3>
                    {fullS.substring(0, i)}
                    <strong style={{ color: color }}>{newS}</strong>
                    {fullS.substring(i + pattern.length, fullS.length)}
                </h3>
            )
        }
    }
    return result
}

export default function HighLightTitle({ title, highlight, color = "red", ...props }) {
    return <div {...props}>{replaceString(highlight, title, color)}</div>
}

HighLightTitle.propTypes = {
    color: string.isRequired,
    title: string.isRequired,
    highlight: string.isRequired,
}
