export default {
    promo_section: {
        title: `[[highlight]] your calls, meetings, and email`,
        highlighted: `Supercharge`,
        color: "#fde169",
        description: `Enrich your business communication tools with data synced with your daily activities.`,
    },
    mission: {
        title: "Our Mission",
        description: `Sync.AI enables you to maximize your productivity by integrating all your communications tools and enhancing them with business data. Your calls, emails, and meetings are connected to provide you with a holistic overview of your business. `,
        items: [
            {
                head: "Synchronized business tools",
                text: "Your business communication tools all working together: Calendar, Calls, & Email",
                icon: require("../assets/images/missions/component-4-3.svg"),
            },
            {
                head: "Enhance your business data",
                text: `View 3rd party business data about leads, customers, and companies`,
                icon: require("../assets/images/missions/component-4-1.svg"),
            },
            {
                head: `Increase your productivity`,
                text: "Additional data and more automation lead to more informed and shorter sales cycles",
                icon: require("../assets/images/missions/component-4-2.svg"),
            },
        ],
    },
    productivity: {
        title: `Discover our [[highlight]] apps`,
        highlighted: `Productivity`,
        color: "#1999fb",
        portfolio: [
            {
                logoImageName: "calendar-logo@3x.png",
                previewImageScreen: "calendar-screen@3x.png",
                previewImageDesktop: "desktop_calendar.png",
                style_theme: "calendar",
                nameVector: require(`../assets/images/portfolio/calendar-name.svg`),
                quote: "Make every meeting count",
                description:
                    "Save time with insights about the contacts and companies you meet and by using the smart schedule to save time",
                anchor_link: "/calendar",
                text_button: "LEARN MORE",
                type: "calendar",
            },
            {
                logoImageName: "calls-logo@3x.png",
                nameVector: require(`../assets/images/portfolio/calls-name.svg`),
                previewImageDesktop: "calls_desktop.png",
                previewImageScreen: "calls-screen@3x.png",
                cutScreen: "calls",
                style_theme: "calls",

                quote: "Turn your dialer into a CRM",
                description:
                    "With Calls.AI you can easily turns calls into tasks. Organize and prioritize your incoming leads so you never miss a sale",
                anchor_link: "/calls",
                text_button: "LEARN MORE",
                type: "calls",
            },
            {
                logoImageName: "logo_sync@3x.png",
                previewImageScreen: "sync-screen@3x.png",
                previewImageDesktop: "sync_desktop.png",
                style_theme: "sync",
                nameVector: require(`../assets/images/portfolio/sync-name.svg`),
                quote: "Data augmented email",
                description:
                    "View critical information regarding incoming emails, from their online presence and company data. The information is automatically synced with your calendar and calls.",
                anchor_link: "/email",
                text_button: "LEARN MORE",
                type: "mail",
            },
        ],
    },
}
